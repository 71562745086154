/* eslint-disable no-empty-pattern */
import { useState, useEffect } from "react";
import { Alert, Button, Card, CustomTable } from "../../Library/Module";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";


import { fetchAndConvertXML, xmlData2 } from "./RssFeedCapture";


export const rssFeedList: any = {
  "rbi": [
    {
      "link": "https://rbi.org.in/pressreleases_rss.xml",
      "title": "Press Releases"
    },
    {
      "link": "https://rbi.org.in/notifications_rss.xml",
      "title": "Notifications"
    },
    {
      "link": "https://rbi.org.in/speeches_rss.xml",
      "title": "Speeches"
    }, {
      "link": "https://rbi.org.in/tenders_rss.xml",
      "title": "Tenders"
    }, {
      "link": "https://rbi.org.in/Publication_rss.xml]",
      "title": "Publication"
    }
  ],
  "sebi": [
    {
      "link": "https://www.sebi.gov.in/sebirss.xml",
      "title": "SEBI"
    }
  ]
}

const ViewRSSFeeds = () => {
  const [showContent, setShowContent] = useState('calender');
  const [grid, setGrid] = useState('reorder')
  const [feedContent, setFeedContent] = useState<any>()
  const [tabList, setTabList] = useState<any>()
  let { title } = useParams();
  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "City Management",
      link: "/dashboard",
    },
    {
      title: "View City",
      link: "/dashboard",
    },
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [subtab, setSubtab] = useState('')
  useEffect(() => {
    if (title && title?.length) {
      const getUpdates = async () => {
        const getURL = rssFeedList ? rssFeedList?.[title ?? ''] : ''
        console.log("getURL", getURL, title)
        if (getURL?.length === 1) {
          const feedContent1 = await fetchAndConvertXML(getURL?.[0]?.link)
          console.log("getUpdates feedContent", feedContent)
          setFeedContent(feedContent1)
          setTabList([])
          setSubtab('')
        } else {
          const feedContent1 = await fetchAndConvertXML(getURL?.[0]?.link)
          console.log("getUpdates feedContent", feedContent)
          setFeedContent(feedContent1)
          setTabList(getURL)
          setSubtab(getURL?.[0]?.title)
        }
      }
      getUpdates()
    }
  }, [title])

  const GetLinkFrom = async (item: any) => {
    const feedContent1 = await fetchAndConvertXML(item?.link)
    console.log("getUpdates feedContent GetLinkFrom", feedContent)
    setFeedContent(feedContent1)
    setSubtab(item?.title)
    return
  }
  return (
    <Wrapper pageTitle="CMS Management" breadcrumbList={breadcrumbList}>
      <Card>
        <div className="">

          <div className="row">
            <div className="col-md-12">
              <div className="pageGropupBtn">
                <NavLink to="/latest-updates-from-world/rbi" className={`btn waves-effect waves-light btn-md btn-outline-link btn- ${showContent === 'rbi' ? 'active' : 'inactive'}`}>RBI</NavLink>
                <NavLink to="/latest-updates-from-world/sebi" className={`btn waves-effect waves-light btn-md btn-outline-link btn- ${showContent === 'sebi' ? 'active' : 'inactive'}`}>SEBI</NavLink>
                {/* <NavLink to="/latest-updates-from-world/mca" className={`btn waves-effect waves-light btn-md btn-outline-link btn- ${showContent === 'mca' ? 'active' : 'inactive'}`}>MCA</NavLink> */}
                {/* <NavLink to="/latest-updates-from-world/cci" className={`btn waves-effect waves-light btn-md btn-outline-link btn- ${showContent === 'cci' ? 'active' : 'inactive'}`}>CCI</NavLink> */}
              </div>
              <Card>
                <div className="">

                  <div className="pageGropupBtn">
                    {tabList?.map((item: any) => {
                      return (
                        <>
                          <Button className={`btn waves-effect waves-light btn-md btn-outline-link btn- ${subtab === item?.title ? 'active' : 'inactive'}`} onClick={() => GetLinkFrom(item)}>{item?.title}</Button>
                        </>
                      )
                    })}
                  </div>


                  {feedContent?.rss ?
                    <>

                      <div className="feedInfo">
                        <h5>{feedContent?.rss?.channel?.title?.['#text']}</h5>
                        <p>{feedContent?.rss?.channel?.description?.['#text']}</p>
                        <p>{feedContent?.rss?.channel?.copyright?.['#text']}</p>
                        <a className="btn waves-effect waves-light btn-md btn-primary" href={feedContent?.rss?.channel?.link?.['#text']} target="_blank" rel="noreferrer" >
                          Visit Website
                        </a>
                      </div>

                      <div className="buttonGroup">
                        <Button onClick={() => setGrid('grid_view')} className={`btn waves-effect waves-light btn-md btn-outline-link ${grid === "grid_view" ? 'active' : ''}`}>
                          <span className="material-symbols-outlined">grid_view</span> </Button>
                        <Button onClick={() => setGrid('reorder')} className={`btn waves-effect waves-light btn-md btn-outline-link ${grid === "reorder" ? 'active' : ''}`}>
                          <span className="material-symbols-outlined">reorder</span>
                        </Button>
                      </div>

                      <div className="listGroup row">
                        {feedContent?.rss?.channel?.item?.map((item: any) => {
                          return (
                            <>
                              <div className={`list ${grid} ${grid === "grid_view" ? 'col-md-3' : 'col-md-12'}`}>
                                <div className="inner">
                                  <h5>{item?.title?.['#cdata-section']} {item?.title?.['#text']} </h5>
                                  <p>Date - {item?.pubDate?.['#text']}</p>
                                  {grid === "grid_view" ? '' :
                                    <div className="desc">
                                      <div dangerouslySetInnerHTML={{ __html: item?.description?.['#cdata-section'] }} />
                                      <div dangerouslySetInnerHTML={{ __html: item?.description?.['#text'] }} />
                                    </div>
                                  }
                                  <a className="btn waves-effect waves-light btn-md btn-primary" href={item?.link?.['#text']} target="_blank" rel="noreferrer" >
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                    : <div className="no noDataFound">No RSS Feed Available for this.</div>
                  }
                </div>
              </Card>
            </div>
          </div>
        </div>
      </Card>
    </Wrapper>
  );
};
export default ViewRSSFeeds;
