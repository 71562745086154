/* eslint-disable no-empty-pattern */
import { useEffect, useMemo, useState } from "react";
import { Alert, Card, Input, Button, Select, DatePickerComp } from "../../Library/Module";

import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import {
    ApplyLeave,
    GetUsers,
    EditLeave,
    GetAssignedManagerByUserEmail,
    GetEvents,
    getMasterLeave,
    GetSetting,
    GetLOPAssignedManagerByUserEmail,
} from "../../servies/services";
import { GetDay, objectToQueryString } from "../../Library/Utility/Utility";
import moment from "moment";
import CustomTooltip from "../../Library/Tooltip/Tippy";
import LeaveBox from "./LeaveBox";
type formDataProps = {
    _id?: string;
    internal_id?: string;
    parent_id?: string;
    meeting_type?: string;
    leave_reason?: string;
    leave_type?: string;
    start_date?: string;
    end_date?: string;
    start_time?: string;
    end_time?: string;
    user_email_id?: string;
    user_name?: string;
    created_by?: string;
    status?: string;
    days?: string;
    isHalfDay?: string
    leaveDayType?: string
};

const statusOption = [
    { text: "Emergency", id: 1, value: "Emergency" },
    { text: "Regular", id: 2, value: "Regular" },
    { text: "Backdated", id: 2, value: "Backdated" },
];


const AddLeave = () => {
    const navigate = useNavigate();
    let { id, type } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const email_id = queryParams.get("email_id");
    const first_name = queryParams.get("first_name");
    const last_name = queryParams.get("last_name");
    const [isError, setIsError]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isLoading, setIsLoading]: any = useState(false);
    const [isValid, setIsValid]: any = useState(true);
    const [formData, setFormData] = useState<formDataProps>();
    const [formDataManager, setFormDataManager] = useState<any>();
    const [days, setDays]: any = useState(0);
    const [masterLength, setMasterLength] = useState<formDataProps>();
    const [isPaidLeave, setIsPaidLeave] = useState({
        days: 0,
        isNegative: false
    })
    const Today: any = moment().add(7, 'days')
    const [isDisable, setIsDisable] = useState(true)

    const [isLoadingleaveData, setIsLoadingLeaveData] = useState(false)
    const [isLoadingleaveData2, setIsLoadingLeaveData2] = useState(false)
    const [isLoadingleaveData3, setIsLoadingLeaveData3] = useState(false)
    const [isLoadingleaveData4, setIsLoadingLeaveData4] = useState(false)
    const [leaveData, setLeaveData] = useState(0)
    const [leaveData2, setLeaveData2] = useState(0)
    const [leaveData3, setLeaveData3] = useState(0)
    const [leaveData4, setLeaveData4] = useState(0)
    const [leaveData5, setLeaveData5] = useState(0)
    
    const [dateList, setDateList] = useState([])
    const [holidayList, setHolidayList] = useState<any>([])
    const [tableData, setTableData] = useState<any>([]);
    const [userDetailsData, setUserDetailsData] = useState<any>();

    const [submitType] = useState(type);
    const [serverError, setServerError]: any = useState(
        "Something went wrong, please try again"
    );
    const [leaveDays, setLeaveDays] = useState(0)
    // @ts-ignore
    const profile_details = JSON.parse(localStorage.getItem("profile_details"));

    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    useEffect(() => {
        setIsLoading(false);
        if (type === "edit" || type === "view") {
            const getData = async () => {
                const query = {
                    "parent_id": id
                }
                const APIResponse = await getMasterLeave(objectToQueryString(query));
                if (APIResponse?.data?.isSuccess === true) {
                    setFormData(APIResponse?.data?.data?.[0]);
                    console.log("formData", APIResponse?.data?.data[0])
                    setIsLoading(true);
                } else {
                    toast.error("Something went wrong, please try again");
                    setIsError(true);
                    setIsLoading(true);
                }
            };
            getData();
        } else {
            setFormData({});
            setIsLoading(true);
        }
    }, [id, type]);


    // useEffect(() => {
    //     const getData = async () => {
    //         const APIResponse = await getMasterLeave();
    //         if (APIResponse?.data?.isSuccess === true) {
    //             setMasterLength(APIResponse?.data?.data?.length);
    //             console.log("APIResponse length", APIResponse?.data?.data?.length)
    //             setIsLoading(true);
    //         } else {
    //             toast.error("Something went wrong, please try again");
    //             setIsError(true);
    //             setIsLoading(true);
    //         }
    //     };
    //     getData();
    // }, []);


    console.log("formData?.parent_id", formData?.parent_id)
    const commonParams = useMemo(() => {
        let min = 100000;
        let max = 1000000000;
        let random = Math.floor(Math.random() * (+max + 1 - +min)) + +min;
        let FName = first_name ?? profile_details?.first_name
        let LName = last_name ?? profile_details?.last_name
        return {
            "parent_id": type === "edit" || type === "view" ? formData?.parent_id : random.toString(),
            "leave_reason": formData?.leave_reason,
            "leave_type": formData?.leave_type,
            "days": leaveDays.toString(),
            "start_date": formData?.start_date,
            "end_date": formData?.end_date,
            "email_id": email_id ?? profile_details?.email_id,
            "user_name": FName + " " + LName,
            "created_by": email_id ?? profile_details?.email_id,
            "status": formDataManager?.managers?.length === 0 ? "Approved" : "Pending",
            "isPaidLeave": (!isPaidLeave?.isNegative).toString(),
            "isLeaveAppovalReq": (formDataManager?.managers?.length > 0).toString(),
            "isHalfDay": formData?.isHalfDay,

        }
    }, [email_id, first_name, formData?.end_date, formData?.isHalfDay, formData?.leave_reason, formData?.leave_type, formData?.parent_id, formData?.start_date, formDataManager?.managers, isPaidLeave?.isNegative, last_name, leaveDays, profile_details?.email_id, profile_details?.first_name, profile_details?.last_name, type])

    const Submit = async (event: any) => {
        const month = moment(formData?.start_date).month()
        const year = moment(formData?.start_date).year()
        const isFirstHalf = month + 1 <= 6
        setIsFormSubmitted(true);
        const managerListData: any = []
        formDataManager?.managers?.length !== 0 && formDataManager?.managers?.map((item: any) => {
            return managerListData.push({
                ...commonParams,
                "remark": "",
                "manager_email_id": item?.value,
                "month": (month + 1).toString(),
                "year": year.toString(),
                "halfYear": isFirstHalf ? '1' : '2',
            })
        })

        if (
            formData?.leave_reason !== undefined &&
            formData?.leave_reason.length !== 0
        ) {

            setIsValid(true);
            let postData = {
                id: formData?._id,
                leave_dates: dateList,
                leave_managers: managerListData,
                "internal_id": new Date(),
                "managers": formDataManager?.managers,
                ...commonParams,
                "month": (month + 1).toString(),
                "year": year.toString(),
                "halfYear": isFirstHalf ? '1' : '2',
            };
            if (submitType === "add") {
                delete postData.id;
                const APIResponse = await ApplyLeave(postData);
                console.log("APIResponse", APIResponse)
                if (APIResponse?.data?.isSuccess === true) {
                    toast.success(APIResponse?.data?.messages);
                    redirectToParent();
                } else {
                    if (APIResponse?.data?.validataion_error) {
                        toast.error(APIResponse?.data?.messages);
                        setServerError(APIResponse?.data?.messages);
                        setIsError(true);
                    } else {
                        toast.error("Something went wrong, please try again");
                        setIsError(true);
                    }
                }
            } else {
                const APIResponse = await EditLeave(commonParams?.parent_id, postData);
                if (APIResponse?.data?.isSuccess === true) {
                    toast.success(APIResponse?.data?.messages);
                    redirectToParent();
                } else {
                    if (APIResponse?.data?.validataion_error) {
                        toast.error(APIResponse?.data?.messages);
                        setServerError(APIResponse?.data?.messages);
                        setIsError(true);
                    } else {
                        toast.error("Something went wrong, please try again");
                        setIsError(true);
                    }
                }
            }
        } else {
            setIsValid(false);
            toast.error("Please enter required fields");
        }
    };

    const redirectToParent = () => {
        navigate("/leaves-management/view-leaves/users");
    };

    const breadcrumbList = [
        {
            title: "Dashboard",
            link: "/dashboard",
        },
        {
            title: "City Management",
            link: "/dashboard",
        },
        {
            title: "Add City",
            link: "/dashboard",
        },
    ];

    useEffect(() => {
        if (formData?.start_date && formData?.end_date) {
            const sDate = moment(formData?.start_date).format('MM/DD/YYYY')
            const eDate = moment(formData?.end_date).format('MM/DD/YYYY')
            const startDate = moment(sDate).format('YYYY-MM-DD');
            const endDate = moment(eDate).format('YYYY-MM-DD');
            const mstartDate = moment(startDate);
            const mendDate = moment(endDate);
            const getDaysBetweenDates2 = (mstartDate: any, mendDate: any) => {
                var now = mstartDate.clone(),
                    dates = [];
                while (now.isSameOrBefore(mendDate)) {
                    console.log("getDaysBetweenDates2 now", moment(now).format('DD/MM/YYYY').toString())
                    console.log("getDaysBetweenDates2 holidayList", holidayList)
                    if (holidayList.includes(moment(now).format('DD/MM/YYYY').toString())) {
                        console.log("Holiday", moment(now).format('DD/MM/YYYY'))
                    } else {
                        const day = moment(now).format('d')
                        console.log("getDaysBetweenDates2 day", day)
                        if (day !== '6') {
                            if (day !== '0') {
                                const month = moment(now).month()
                                const year = moment(now).year()
                                const isFirstHalf = month + 1 <= 6
                                console.log("day now", now.format('DD/MM/YYYY'))
                                dates.push({
                                    "date": now.format('DD/MM/YYYY'),
                                    "month": (month + 1).toString(),
                                    "year": year.toString(),
                                    "halfYear": isFirstHalf ? '1' : '2',
                                    "leaveDay": formData?.isHalfDay === 'Full Day' ? '1' : now.format('DD/MM/YYYY')?.toString() === formData?.isHalfDay?.toString() ? '0.5' : '1',
                                    ...commonParams
                                })
                            }
                        }
                    }
                    now.add(1, 'days')
                }
                return dates;
            };
            const dateList2: any = getDaysBetweenDates2(mstartDate, mendDate);
            console.log("dateList outise", dateList2)
            setDateList(dateList2)
        }
    }, [formData?.start_date, formData?.end_date, commonParams, holidayList, formData?.isHalfDay])

    const getManagersByUser = async (userEmailId: any) => {
        const APIResponse = await GetAssignedManagerByUserEmail(userEmailId);
        if (APIResponse?.data?.isSuccess === true) {
            console.log("APIResponse?.data?.data", APIResponse?.data?.data)
            const data = {
                managers: APIResponse?.data?.data
            }
            setFormDataManager(data);

        } else {
            toast.error("Something went wrong, please try again");
            // setIsLoading(true)
        }
    };
    const [isLOPAssigned, setIsLOPAssigned] = useState(false)

    const getLOPManagersByUser = async (userEmailId: any) => {
        const APIResponse = await GetLOPAssignedManagerByUserEmail(userEmailId);
        if (APIResponse?.data?.isSuccess === true) {
            console.log("APIResponse?.data?.data", APIResponse?.data?.data)
            const data = {
                managers: APIResponse?.data?.data
            }
            if (APIResponse?.data?.data?.length > 0) {
                setIsLOPAssigned(true)
            }

            setFormDataManager(data);

        } else {
            toast.error("Something went wrong, please try again");
            // setIsLoading(true)
        }
    };


    const getUserDetailsByEmailId = async (userEmailId: any) => {
        // setIsLoading(false);
        setUserDetailsData({});
        const query = {
            "email_id": userEmailId
        }
        const apidata = await GetUsers(objectToQueryString(query));
        const APIResponse = apidata?.data?.data;
        console.log("APIResponse setUserDetailsData", APIResponse)
        setUserDetailsData(APIResponse?.[0]);
        // setIsLoading(true);


    }


    useEffect(() => {
        console.log("email_id", email_id)
        if (email_id ?? profile_details?.email_id) {
            getManagersByUser(email_id ?? profile_details?.email_id)
            getUserDetailsByEmailId(email_id ?? profile_details?.email_id)
        }
    }, [email_id, profile_details?.email_id, formData?.start_date, formData?.end_date])



    useEffect(() => {
        console.log("formData?.isHalfDay", formData?.isHalfDay)
        if (formData?.leave_reason && formData?.leave_type && formData?.start_date && formData?.end_date && formData?.isHalfDay) {
            setIsDisable(false)
        } else {
            setIsDisable(true)
        }
    }, [formData?.end_date, formData?.isHalfDay, formData?.leave_reason, formData?.leave_type, formData?.start_date])


    useEffect(() => {
        setLeaveData(0);
        setIsLoadingLeaveData(false)
        const getData = async () => {
            const month = moment(formData?.start_date).month();
            const year = moment(formData?.start_date).year();
            const isFirstHalf = month + 1 <= 6
            const query = {
                "email_id": email_id ?? profile_details?.email_id,
                "status": "Approved",
                "halfYear": isFirstHalf ? '1' : '2',
                "year": 2023
            }
            const apidata: any = await getMasterLeave(objectToQueryString(query));
            const APIResponse = apidata?.data?.data;
            let valueInBeginning = 0;
            let totalExpenditure = APIResponse.reduce((accumVariable: any, curValue: any) => accumVariable + Number(curValue.days), valueInBeginning)
            setLeaveData(totalExpenditure);
            setIsLoadingLeaveData(true)
        };
        getData();
    }, [email_id, formData?.start_date, profile_details?.email_id])


    useEffect(() => {
        setLeaveData2(0);
        setIsLoadingLeaveData2(false)
        const getData = async () => {
            const query = {
                "email_id": email_id ?? profile_details?.email_id,
                "status": "Approved",
                "halfYear": "1",
                "year": 2024
            }
            const apidata: any = await getMasterLeave(objectToQueryString(query));
            const APIResponse = apidata?.data?.data;
            let valueInBeginning = 0;
            let totalExpenditure = APIResponse.reduce((accumVariable: any, curValue: any) => accumVariable + Number(curValue.days), valueInBeginning)
            setLeaveData2(totalExpenditure);
            setIsLoadingLeaveData2(true)
        };
        getData();
    }, [email_id, formData?.start_date, profile_details?.email_id])


    useEffect(() => {
        setLeaveData3(0);
        setIsLoadingLeaveData3(false)
        const getData = async () => {
            const query = {
                "email_id": email_id ?? profile_details?.email_id,
                "status": "Approved",
                "halfYear": "2",
                "year": 2024
            }
            const apidata: any = await getMasterLeave(objectToQueryString(query));
            const APIResponse = apidata?.data?.data;
            let valueInBeginning = 0;
            let totalExpenditure = APIResponse.reduce((accumVariable: any, curValue: any) => accumVariable + Number(curValue.days), valueInBeginning)
            setLeaveData3(totalExpenditure);
            setIsLoadingLeaveData3(true)
        };
        getData();
    }, [email_id, formData?.start_date, profile_details?.email_id])


    useEffect(() => {
        setLeaveData4(0);
        setIsLoadingLeaveData4(false)
        const getData = async () => {
            const query = {
                "email_id": email_id ?? profile_details?.email_id,
                "status": "Approved",
                "halfYear": "1",
                "year": 2025
            }
            const apidata: any = await getMasterLeave(objectToQueryString(query));
            const APIResponse = apidata?.data?.data;
            let valueInBeginning = 0;
            let totalExpenditure = APIResponse.reduce((accumVariable: any, curValue: any) => accumVariable + Number(curValue.days), valueInBeginning)
            setLeaveData4(totalExpenditure);
            setIsLoadingLeaveData4(true)
        };
        getData();
    }, [email_id, formData?.start_date, profile_details?.email_id])

    useEffect(() => {
        const month = moment(formData?.start_date).month()
        const yearTo = moment(formData?.start_date).year()
        const isFirstHalf = month + 1 <= 6
        const first = isFirstHalf ? '1' : '2'
        console.log("-------------- isFirstHalf ------------------", isFirstHalf, month, first, yearTo)
        let finalLeaves
        if (formData?.isHalfDay === 'Full Day') {
            finalLeaves = dateList?.length
        } else {
            finalLeaves = dateList?.length - Number(0.5)
        }
        console.log("finalLeaves", finalLeaves)
        // let leave = first === "2" ? (userDetailsData?.leaves2 - leaveData) - finalLeaves : (userDetailsData?.leaves - leaveData2) - finalLeaves


        let leave =
            yearTo === 2025 ?
                first === "1" ? (userDetailsData?.leaves4 - leaveData4) - finalLeaves : 
                first === "2" ? (userDetailsData?.leaves5 - leaveData5) - finalLeaves : 0 :
                yearTo === 2024 ?
                    first === "2" ? (userDetailsData?.leaves3 - leaveData3) - finalLeaves :
                        first === "1" ? (userDetailsData?.leaves2 - leaveData) - finalLeaves : 0 :
                    yearTo === 2023 ?
                        first === "2" ? (userDetailsData?.leaves - leaveData2) - finalLeaves :
                            first === "1" ? 0 : 0 : 0

        // firs t === "2" ? (userDetailsData?.leaves2 - leaveData) - finalLeaves : (userDetailsData?.leaves - leaveData2) - finalLeaves

        console.log("total leave July - Dec 2023", userDetailsData?.leaves2, "Approved", leaveData, "applied", finalLeaves, "leave", leave, "first", first)
        console.log("total leave 2 Jan - June 2024", userDetailsData?.leaves, "Approved", leaveData2, "applied", finalLeaves, "leave", leave, "first", first)
        console.log("total leave 3 July - Dec 2024", userDetailsData?.leaves3, "Approved", leaveData3, "applied", finalLeaves, "leave", leave, "first", first)
        console.log("total leave 4 July - Dec 2025", userDetailsData?.leaves4, "Approved", leaveData4, "applied", finalLeaves, "leave", leave, "first", first)

        setIsPaidLeave({
            days: leave,
            isNegative: leave >= 0
        })
    }, [dateList?.length, days, formData?.isHalfDay, formData?.start_date, leaveData, leaveData2, leaveData3, leaveData4, leaveData5, userDetailsData?.leaves, userDetailsData?.leaves2, userDetailsData?.leaves3, userDetailsData?.leaves4, userDetailsData?.leaves5])

    useEffect(() => {
        const getData = async () => {
            const query = {
                type: "Holiday"
            }
            const APIResponse = await GetEvents(objectToQueryString(query));
            if (APIResponse?.data?.isSuccess === true) {
                const daysHolidatList: any = []
                APIResponse?.data?.data?.map((item: any) => {
                    return daysHolidatList.push(moment(item?.date).format('DD/MM/YYYY'))
                })
                console.log("daysHolidatList", daysHolidatList)

                setHolidayList(daysHolidatList);
            } else {
                toast.error("Something went wrong, please try again");
                setIsError(true);
            }
        };
        getData();
    }, []);

    // Get CEO Details
    const [fromLOPSetting, setFromLOPSetting] = useState(true)
    useEffect(() => {
        console.log("!isPaidLeave?.isNegative", isPaidLeave)

        if (formData?.start_date && formData?.end_date) {
            if (isPaidLeave?.isNegative === false && isPaidLeave?.days < 0) {

                getLOPManagersByUser(email_id ?? profile_details?.email_id)

                if (!isLOPAssigned) {

                    const getDataLOPSettings = async () => {
                        const query = {
                            type: 'lop_email_address'
                        }
                        let apidata = await GetSetting(objectToQueryString(query));
                        const APIResponse = apidata?.data?.data;
                        console.log("APIResponse GetSetting", APIResponse)
                        if (APIResponse?.length !== 0) {
                            setFromLOPSetting(true)
                            let newData: any = []
                            APIResponse.map((item: any) => {
                                console.log("Manager item", item)
                                return newData.push(
                                    {
                                        created_by: item?.created_by,
                                        created_on: item?.created_on,
                                        status: "1",
                                        text: item?.first_name + ' ' + item?.last_name,
                                        updated_on: item?.updated_on,
                                        user_email_id: email_id ?? profile_details?.email_id,
                                        value: item?.value,
                                        _id: item?._id
                                    }
                                )
                            })
                            const data = {
                                managers: newData
                            }
                            setFormDataManager(data);
                        } else {
                            setFromLOPSetting(false)
                            setTableData([]);
                            const getData = async () => {
                                const query = {
                                    designation: "CEO"
                                }
                                const apidata = await GetUsers(objectToQueryString(query));
                                const APIResponse = apidata?.data?.data;
                                let newData: any = []
                                APIResponse.map((item: any) => {
                                    console.log("Manager item", item)
                                    return newData.push(
                                        {
                                            created_by: item?.created_by,
                                            created_on: item?.created_on,
                                            status: "1",
                                            text: item?.first_name + ' ' + item?.last_name,
                                            updated_on: item?.updated_on,
                                            user_email_id: email_id ?? profile_details?.email_id,
                                            value: item?.email_id,
                                            _id: item?._id
                                        }
                                    )
                                })
                                const data = {
                                    managers: newData
                                }
                                setFormDataManager(data);
                            };
                            getData();

                        }
                    };
                    getDataLOPSettings();
                }
            }
        }
    }, [email_id, formData?.end_date, formData?.start_date, isLOPAssigned, isPaidLeave, isPaidLeave?.isNegative, profile_details?.email_id])

    const [halfdayDate, setHalfdayDate] = useState<any>([])

    useEffect(() => {
        console.log("formData?.start_date", formData?.start_date)
        console.log("formData?.end_date", formData?.end_date)
        console.log("formData?.start_date === formData?.end_date", (formData?.start_date)?.toString() == (formData?.end_date)?.toString())
        const LeaveTypeDay = [
            { text: "Full Day", id: 2, value: "Full Day" },
            ...((moment(formData?.start_date).format("DD/MM/YYYY"))?.toString() === (moment(formData?.end_date).format("DD/MM/YYYY"))?.toString() ? [
                { text: `Half day on ${moment(formData?.start_date).format("DD/MM/YYYY")}`, id: 1, value: moment(formData?.start_date).format("DD/MM/YYYY") },
            ] : [{ text: `Half day on ${moment(formData?.start_date).format("DD/MM/YYYY")}`, id: 1, value: moment(formData?.start_date).format("DD/MM/YYYY") },
            { text: `Half day on ${moment(formData?.end_date).format("DD/MM/YYYY")}`, id: 2, value: moment(formData?.end_date).format("DD/MM/YYYY") },]),

        ];

        setHalfdayDate(LeaveTypeDay)

    }, [formData?.start_date, formData?.end_date])



    useEffect(() => {
        const days = dateList?.length === 0 ? dateList?.length : formData?.isHalfDay === 'Full Day' ? dateList?.length : dateList?.length - 0.5
        setLeaveDays(days)

    }, [dateList, formData?.isHalfDay])


    const minDate = useMemo(() => {
        return formData?.leave_type === 'Backdated' ? moment(new Date()).subtract(30, 'days') : formData?.leave_type === 'Emergency' ? moment(new Date()) : moment(new Date()).add(7, 'days')
    }, [formData?.leave_type])

    const [year, setYear] = useState("")
    const [month, setMonth] = useState("")
    const [halfYear, setHalfYear] = useState("")

    useEffect(() => {
        const month1 = moment(formData?.start_date).month()
        const year1 = moment(formData?.start_date).year()
        const isFirstHalf = month1 + 1 <= 6
        setYear(year1.toString())
        setMonth(month1.toString())
        setHalfYear(isFirstHalf.toString())
    }, [formData?.start_date, formData?.end_date])

    return (
        <Wrapper pageTitle="Dashboard" breadcrumbList={breadcrumbList}>
            <Card title="Apply for Leave">
                <p className="text-left mb-4">
                    You can manage the Leave data in this module.
                </p>
                <div className="">
                    {isError && (
                        <Alert AlertStyleType="danger" AlertText={serverError}></Alert>
                    )}
                    {!isValid && (
                        <Alert
                            AlertStyleType="danger"
                            AlertText={`Please enter required fields`}
                        ></Alert>
                    )}
                    {isLoading && (
                        <div className="row">
                            <div className="col-md-8">
                                <Input
                                    inputName="leave_reason"
                                    inputType="text"
                                    labelName={"Leave Reason"}
                                    placeholder="Enter Leave Reason"
                                    required={true}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"
                                    isFormSubmitted={isFormSubmitted}
                                    disabled={submitType === "view"}
                                    value={
                                        type === "add" && formData?.leave_reason?.length === 0
                                            ? ""
                                            : formData?.leave_reason
                                    }
                                />
                                <Select
                                    inputName="leave_type"
                                    labelName="Leave Type"
                                    options={statusOption}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    selectedItem={statusOption.find(
                                        (item) => item.value === formData?.leave_type
                                    )}
                                    required={true}
                                    placeholder="Select leave type"
                                    search_option={false}
                                    disabled={type === "view"}
                                    isLoading={true}
                                ></Select>
                                <DatePickerComp
                                    inputName="start_date"
                                    labelName={"Start date"}
                                    placeholder="Booking Start Date"
                                    required={true}
                                    disabled={formData?.leave_type === undefined}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    value={formData?.start_date}
                                    minDate={new Date(minDate.toString())}
                                    col="12"></DatePickerComp>

                                <DatePickerComp
                                    inputName="end_date"
                                    labelName={"End date"}
                                    placeholder="Select booking end date"
                                    required={true}
                                    disabled={formData?.leave_type === undefined}
                                    value={formData?.end_date}
                                    minDate={formData?.start_date ? new Date(formData?.start_date) : new Date(minDate.toString())}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    col="12"></DatePickerComp>

                                <Select
                                    inputName="isHalfDay"
                                    labelName="Leave Day Type"
                                    options={halfdayDate ?? []}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    selectedItem={halfdayDate.find(
                                        (item: any) => item.value === formData?.isHalfDay
                                    )}
                                    required={true}
                                    placeholder="Select Is Half Day Leave"
                                    search_option={false}
                                    disabled={type === "view" || (formData?.start_date === undefined && formData?.end_date === undefined)}
                                    isLoading={true}
                                ></Select>

                                <div className="row align-content-end justify-content-end">
                                    <div className="col-sm-2 text-right">
                                        <Button
                                            onClick={() => redirectToParent()}
                                            buttonText={"Cancel"}
                                            buttonStyleType={"primary"}
                                        />
                                    </div>
                                    <div className="col-sm-2 text-right">
                                        <Button
                                            onClick={() => Submit("")}
                                            disabled={isDisable}
                                            buttonText={
                                                submitType === "add" || submitType === "view"
                                                    ? "Submit"
                                                    : "Update"
                                            }
                                            buttonStyleType={"primary"}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <LeaveBox isLoading={isLoadingleaveData4} userDetailsData={userDetailsData?.leaves4} title={'From Jan - June 2025'} leaveData={leaveData4}></LeaveBox>
                                <LeaveBox isLoading={isLoadingleaveData3} userDetailsData={userDetailsData?.leaves3} title={'From July - Dec 2024'} leaveData={leaveData3}></LeaveBox>
                                {/* <LeaveBox isLoading={isLoadingleaveData2} userDetailsData={userDetailsData?.leaves} title={'From Jan - June 2024'} leaveData={leaveData2}></LeaveBox> */}
                                {/* <LeaveBox isLoading={isLoadingleaveData} userDetailsData={userDetailsData?.leaves2} title={'From July - Dec 2023'} leaveData={leaveData}></LeaveBox> */}

                                {formData?.isHalfDay &&
                                    <div className="box">
                                        <CustomTooltip
                                            title={"title"}
                                            position={"top"}
                                            width="350px"
                                            disabled={dateList?.length === 0}
                                            content={<div className="tooltipContent">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Sr</th>
                                                            <th>Date</th>
                                                            <th>Day</th>
                                                            <th>Full Day</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dateList?.map((item: any, index: number) => {
                                                            const date = moment(item?.date, 'DD-MM-YYYY')
                                                            console.log("asdasd", GetDay(moment(date).day()))
                                                            const days = GetDay(moment(date).day())
                                                            const cdate = moment(date).format("DD/MM/YYYY")
                                                            console.log("cdate", cdate)
                                                            console.log("formData?.isHalfDay", formData?.isHalfDay, cdate, formData?.isHalfDay, moment(date).format("DD/MM/YYYY"))
                                                            return (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item?.date}</td>
                                                                    <td>{days}</td>
                                                                    <td>{formData?.isHalfDay?.toString() === cdate?.toString() ? <span className="tag">Half Day</span> : 'Full Day'}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>}>
                                            <div>
                                                <p>Applying Leaves</p>
                                                <h4>{leaveDays}</h4>
                                            </div>
                                        </CustomTooltip>
                                    </div>
                                }

                                {formData?.start_date && formData?.end_date && formData?.isHalfDay && !isPaidLeave?.isNegative &&
                                    <div className="box box-red">
                                        <p>LOP</p>
                                        <h4>{Math.abs(isPaidLeave?.days)}</h4>
                                    </div>}

                                {!isLOPAssigned ?
                                    <>
                                        <p className="m-0">Managers</p>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Email ID</th>
                                                    <th>Role</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formDataManager?.managers?.length !== 0 && formDataManager?.managers?.map((item: any) => {
                                                    return <tr>
                                                        <td>{item?.value}</td>
                                                        <td>{!isPaidLeave?.isNegative ? !fromLOPSetting ? 'CEO' : 'Global Setting' : 'Leave Approver'}</td>
                                                    </tr>
                                                })}
                                                {formDataManager?.managers?.length === 0 && <tr>
                                                    <td className="" colSpan={3}>
                                                        <div className="noDataFound">
                                                            <p>Leave approval is not required for {email_id ?? profile_details?.email_id} user.</p>
                                                            <p>Leave will be automatically get approved</p>
                                                        </div>
                                                    </td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </>
                                    : 'LOP Manager Assigned'
                                }
                            </div>

                        </div>
                    )}
                </div>
            </Card>
        </Wrapper>
    );
};
export default AddLeave;
