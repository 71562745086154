export const fetchAndConvertXML = async (url) => {
  try {
    // Fetch the XML data
    const response = await fetch(url);
    const xmlText = await response.text();
    console.log("xmlText", xmlText)
    // Parse the XML data
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, 'application/xml');
    console.log("xmlDoc", xmlDoc)
    // Convert XML to JSON (manually or using a library)
    const json = xmlToJson(xmlDoc);
    console.log('Converted JSON:', json, url);
    return json
  } catch (error) {
    console.error('Error fetching or parsing the XML data:', error);
  }
}

// Function to convert XML to JSON
function xmlToJson(xml) {

  let obj = {};
  if (xml.nodeType === 1) { // Element
    if (xml.attributes.length > 0) {
      obj['@attributes'] = {};
      for (let i = 0; i < xml.attributes.length; i++) {
        const attribute = xml.attributes.item(i);
        obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType === 3 || xml.nodeType === 4) { // Text

    obj = xml.nodeValue;
  }

  // Process child nodes

  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes.item(i);
      const nodeName = item.nodeName;
      if (typeof obj[nodeName] === 'undefined') {

        obj[nodeName] = xmlToJson(item);
      } else {

        if (typeof obj[nodeName].push === 'undefined') {
          obj[nodeName] = [obj[nodeName]];
        }
        console.log("obj[nodeName]", obj[nodeName], [obj[nodeName]])
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }

  return obj;
}

export const xmlData2 = () => {
  const xmlData = `
<item>
  <title><![CDATA[ Premature redemption under Sovereign Gold Bond (SGB) Scheme - Redemption Price for premature redemption due on November 12, 2024 (Series I of SGB 2017-18) ]]></title>
  <description></description>
</item>
`;
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlData, 'application/xml');
  console.log('Converted JSON: asdasd', xmlToJson(xmlDoc));
  return
}