import React, { useState } from "react"
const LeaveBox = ({
    userDetailsData,
    leaveData,
    title,
    isLoading
}: any) => {
    const isLOP = userDetailsData - leaveData
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <div className="box">
                <div className="header">
                    <p><strong>{title}</strong></p>
                </div>
                <div className="innerBox">
                    <p>Total Leaves</p>
                    <h4>{!isLoading ? '-' : userDetailsData ?? 0}</h4>
                    <p>Approved Leaves</p>
                    <h4>{!isLoading ? '-' : leaveData}</h4>
                    <p>Remaining Leaves</p>
                    <h4>{!isLoading ? '-' : isLOP > 0 ? isLOP : '0'}</h4>
                    <p>LOP</p>
                    <h4>{!isLoading ? '-' : isLOP > 0 ? 0 : Math.abs(isLOP)}</h4>
                </div>
            </div>
        </>
    )
}
export default LeaveBox