/* eslint-disable no-empty-pattern */
import { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import {
  Alert,
  Card,
  Input,
  Button,
  Select,
  CustomTable,
  SideDrawer,
} from "../../Library/Module";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../Wrapper";
import { getDaysLeave, getMasterManagerLeave, UpdateMasterStatus, GetMasterBookAppointmentByuser, getMasterLeave, GetSingleBookAppointment, GetSingleBookAppointmentByuser, getTransactionLeave, GetUsers, UpdateManagerStatus } from "../../servies/services";
import LeaveRow from "./LeaveRow";
import moment from "moment";
import InlineCalendar from '../../Library/InlineCalendar/InlineCalendar'
import { objectLenght, objectToQueryString } from "../../Library/Utility/Utility";
import TableDownload from "../../Library/CustomTable/TableDownload";
import { LeaveCalenderDownloadKey, LeaveTableDownloadKey } from "../../Functions/Common";
import Filters from "../../Library/Filter/Filter";
import CustomTooltip from "../../Library/Tooltip/Tippy";
import LeaveBox from "./LeaveBox";

type formDataProps = {
  title: string;
  status: string;
  image?: string;
};

const tableConfig = {
  search: {
    name: "id",
    isAsc: false,
  },
  sortDefault: {
    name: "created_on",
    isAsc: false,
  },
  tableHeader: [
    {
      name: "_id",
      display: "Id",
      sort: true,
      sortType: "number",
    },
    {
      name: "user_name",
      display: "User Name",
      sort: true,
    },
    {
      name: "leave_reason",
      display: "leave_reason",
      sort: true,
    },

    {
      name: "start_date",
      display: "Leave Start Date",
      sort: true,
    },

    {
      name: "end_date",
      display: "Leave Start Date",
      sort: true,
    },
    {
      name: "leave_type",
      display: "leave_type",
      sort: true,
    },
    {
      name: "status",
      display: "status",
      sort: true,
    },
    {
      name: "days",
      display: "days",
      sort: true,
    },
    {
      name: "month",
      display: "month",
      sort: true,
    },
    {
      name: "year",
      display: "year",
      sort: true,
    },
    {
      name: "Half Year",
      display: "Half Year",
      sort: true,
    },
    {
      name: "created_on",
      display: "Applied Date",
      sort: true,
    },
    {
      name: "Action",
      display: "Action",
      sort: false,
    },
  ],
};

type actionTypeState = {
  id?: any;
  type?: any;
  data?: any;
  mainType?: any
};



const AddConferenceRoom = () => {
  const navigate = useNavigate();
  const [isError, setIsError]: any = useState(false);
  let { leaveType } = useParams();
  const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
  const [isValid, setIsValid]: any = useState(true);
  const [tableDataCalendar, setTableDataCalendar] = useState<any>([]);
  const [tableDataCalendarSingle, setTableDataCalendarSingle] = useState<any>();
  const [tableDataManagerSingle, setTableDataManagerSingle] = useState<any>();

  const [tableData, setTableData] = useState<any>([]);
  const [userData, setUserData] = useState<any>([]);
  const [showCells, setShowCells] = useState(null);
  const [sortState, setSortState] = useState(tableConfig.sortDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadAPI, setReloadAPI] = useState(false);
  const [showContent, setShowContent] = useState(leaveType === 'allUsers' ? 'table' : 'calender');
  const [filterParam, setFilterParam] = useState<any>()
  const [userList, setUserList] = useState<any>()
  // @ts-ignore
  const profile_details = JSON.parse(localStorage.getItem("profile_details")) || null;
  const [actionType, setActionType] = useState<actionTypeState>({});
  // @ts-ignore
  const isLOPAssignee = JSON.parse(localStorage.getItem("is_lopAssigned")) || null;
  const showCellCallback = (data: any) => {
    setShowCells(data);
  };

  useEffect(() => {
    setIsLoading(false);
    setUserData([]);
    const getData = async () => {
      const apidata = await GetUsers();
      const APIResponse = apidata?.data?.data;
      setUserList(APIResponse)
      const newData: any = [
        { text: "All", id: 0, value: 'All' }
      ]
      APIResponse?.map((item: any) => {
        console.log("item", item)
        return newData.push(
          { text: item?.first_name + ' ' + item?.last_name, id: 3, value: item?.email_id }
        )
      })
      setUserData(newData);
      setIsLoading(true);
      setReloadAPI(false);
    };
    getData();
  }, [reloadAPI]);


  const statusFilterOption = useMemo(() => {
    return [
      ...(profile_details?.user_type === 'admin' || showContent === 'table' ? [
        { text: "Pending", id: 1, value: "Pending" },
        { text: "All", id: 0, value: "0" },
        { text: "Approved", id: 2, value: "Approved" },
        { text: "Rejected", id: 3, value: "Rejected" }
      ] :
        [
          { text: "Pending", id: 1, value: "Pending" }
        ]),
    ]
  }, [profile_details?.user_type, showContent])

  const useFilter = useMemo(() => {
    return [
      ...(leaveType === 'allUsers' ? [{
        "type": "dropdown",
        "name": 'email_id',
        "label": "User",
        "placeholder": "Select User",
        "default": userData?.[0],
        "options": userData
      }] : [])
    ]
  }, [leaveType, userData])

  console.log("filterParam asdasd", filterParam)

  // useEffect(() => {
  //   const getData = async () => {
  //     const apidata = await ModifyLeaveDate();
  //     const APIResponse = apidata?.data?.data;
  //     console.log("APIResponse ModifyLeaveDate", APIResponse);
  //   }
  //   getData()
  // },[])

  useEffect(() => {
    setIsLoading(false);
    const getData = async () => {
      const query = {
        sortBy: 'created_on',
        ...(leaveType === 'users' ? { "email_id": profile_details?.email_id } :
          {
            ...filterParam
          }),
        ...(profile_details?.user_type === 'leave-approver' && leaveType === 'allUsers' ? { "status": "Pending" } : {})
      }

      const apidata = await getMasterLeave(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;

      if (profile_details?.user_type === 'admin') {
        setTableData(APIResponse);
      } else if (profile_details?.user_type === 'leave-approver') {
        if (leaveType === 'allUsers') {
          // console.log("APIResponse Master", APIResponse)
          const updatedList: any = []
          APIResponse?.filter((master: any) => {
            if (master?.managers?.length === 0) {
              console.log("APIResponse Master master?.managers?.length", master)
              updatedList.push(master)
            } else {
              return master?.managers?.filter((item: any) => {
                if (item?.value === profile_details?.email_id && item?.status === '1') {
                  console.log("APIResponse Master item?.status === '1'", master)
                  updatedList.push(master)
                }
                return true
              })
            }
          })
          console.log("APIResponse Master updatedList", updatedList)

          setTableData(updatedList);
        } else {
          setTableData(APIResponse);
        }
      } else {
        setTableData(APIResponse);
      }

    };
    getData();
    setTableDataCalendar([]);
    const getData2 = async () => {
      const apidata = await getDaysLeave();
      const APIResponse = apidata?.data?.data;
      setTableDataCalendar(APIResponse);
    };
    getData2();
    setIsLoading(true);
    setReloadAPI(false);

  }, [reloadAPI, leaveType, profile_details?.email_id, filterParam, profile_details?.user_type]);

  const breadcrumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "City Management",
      link: "/dashboard",
    },
    {
      title: "View City",
      link: "/dashboard",
    },
  ];
  const reloadData = (data: any) => {
    console.log("reloadData", data);
    setReloadAPI(true);
  };

  const CloseDrawer = () => {
    setActionType({});
  };

  const callbackFresh = (data: any) => {
    console.log('callbackFresh data', data?.data?.email_id)
    setActionType(data)
    const parent_id = [data?.data?.parent_id]
    if (data?.mainType === 'schedule') {
      setTableDataCalendarSingle([]);
      const getData2 = async () => {
        const query = {
          parent_id: parent_id,
        }
        const apidata = await getDaysLeave(objectToQueryString(query));
        const APIResponse = apidata?.data?.data;
        setTableDataCalendarSingle(APIResponse);
      };
      getData2();
    } else {
      setTableDataManagerSingle([]);
      getTransactionLeaveAPI(parent_id);
      getTotalLeaves(data?.data?.email_id)
    }
  }


  const getTransactionLeaveAPI = async (parent_id: any) => {
    const query = {
      parent_id: parent_id
    }
    const apidata = await getTransactionLeave(objectToQueryString(query));
    const APIResponse = apidata?.data?.data;
    setTableDataManagerSingle(APIResponse);
  };

  const pageData = 'Leave Management'
  const pageDescData = leaveType === 'allUsers' ? 'You can approve or reject leaves in this section' : 'You can manage the Leave Management data in this module.'
  const [formData, setFormData] = useState<any>();
  const onChangeSingleCallback = (data: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      ...data,
    }));
    setIsFormSubmitted(false);
    ChangeStatus(data)
  };

  const meetingRoomName = [
    { text: "Approved", id: 1, value: "Approved" },
    { text: "Rejected", id: 2, value: "Rejected" }
  ];


  const UpdateMasterStatusAPI = async (data: any, selected: string) => {
    const newDate: any = []
    data?.managers?.map((item: any) => {
      if (item.value === profile_details?.email_id) {
        let newddd = item
        newddd.status = selected
        return newDate.push(newddd)
      } else {
        return newDate.push(item)
      }
    })
    data.managers = newDate
    console.log("UpdateMasterStatusAPI newDate", newDate)
    console.log("UpdateMasterStatusAPI Data", data)
    const apidata = await UpdateMasterStatus(data);
    const APIResponse = apidata?.data?.data;
    console.log("update", APIResponse)
  };

  const ChangeStatus = async (data: any) => {
    const key = Object.keys(data)?.[0]
    const value: any = Object.values(data)?.[0]
    console.log("value selected", value)
    const getData2 = async () => {
      const query = {
        id: key,
        status: value
      }
      const apidata = await UpdateManagerStatus(query);
      const APIResponse = apidata?.data;
      console.log("APIResponse", APIResponse)
      if (APIResponse?.isSuccess === true) {
        toast.info("Status has been changed succesfully.");
      } else {
        toast.error("Something went wrong, please try again");
      }
    };
    await getData2();
    // await UpdateMasterStatusAPI(actionType?.data, value)
    console.log("actionType?.data", actionType?.data?.email_id)
    await getTransactionLeaveAPI(actionType?.data?.parent_id);
    getTotalLeaves(actionType?.data?.email_id)
    setReloadAPI(true)

  }



  const filterData = useCallback((data) => {
    console.log("filterData", data)
    let newObj = {}
    for (const [key, value] of Object.entries(data)) {
      console.log('filterData', `${key}: ${value}`);
      if (value !== 'All') {
        const source = { [key]: value }
        Object.assign(newObj, source)
      }
    }
    setFilterParam(newObj)
  }, [])

  console.log("filterParam", filterParam)

  const [leaveData, setLeaveData] = useState(0)
  const [userDetailsData, setUserDetailsData] = useState<any>();
  const [leaveData2, setLeaveData2] = useState(0)
  const [leaveData3, setLeaveData3] = useState(0)
  const [leaveData4, setLeaveData4] = useState(0)

  const [isLoadingleaveData, setIsLoadingLeaveData] = useState(false)
  const [isLoadingleaveData2, setIsLoadingLeaveData2] = useState(false)
  const [isLoadingleaveData3, setIsLoadingLeaveData3] = useState(false)
  const [isLoadingleaveData4, setIsLoadingLeaveData4] = useState(false)

  const getTotalLeaves = useCallback((email_id) => {
    setIsLoading(false);
    setLeaveData(0);
    setIsLoadingLeaveData(false)
    const getData = async () => {
      const query = {
        "email_id": email_id,
        "status": "Approved",
        "halfYear": '2',
        "year": 2023,

      }
      const apidata: any = await getMasterLeave(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      let valueInBeginning = 0;
      let totalExpenditure = APIResponse.reduce((accumVariable: any, curValue: any) => accumVariable + Number(curValue.days), valueInBeginning)
      setLeaveData(totalExpenditure);
      setIsLoadingLeaveData(true)
      setIsLoading(true);
      const user = userList?.find((item: any) => {
        return item?.email_id === email_id
      })
      console.log("user", user)
      setUserDetailsData(user)
    };
    getData();


    setLeaveData2(0);
    setIsLoadingLeaveData2(false)
    const getData2 = async () => {
      const query = {
        "email_id": email_id,
        "status": "Approved",
        "halfYear": "1",
        "year": 2024
      }
      const apidata: any = await getMasterLeave(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      let valueInBeginning = 0;
      let totalExpenditure = APIResponse.reduce((accumVariable: any, curValue: any) => accumVariable + Number(curValue.days), valueInBeginning)
      setLeaveData2(totalExpenditure);
      setIsLoadingLeaveData2(true)
    };
    getData2();

    setLeaveData3(0);
    setIsLoadingLeaveData3(false)
    const getData3 = async () => {
      const query = {
        "email_id": email_id,
        "status": "Approved",
        "halfYear": "2",
        "year": 2024
      }
      const apidata: any = await getMasterLeave(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      let valueInBeginning = 0;
      let totalExpenditure = APIResponse.reduce((accumVariable: any, curValue: any) => accumVariable + Number(curValue.days), valueInBeginning)
      setLeaveData3(totalExpenditure);
      setIsLoadingLeaveData3(true)
    };
    getData3();


    setLeaveData4(0);
    setIsLoadingLeaveData4(false)
    const getData4 = async () => {
      const query = {
        "email_id": email_id,
        "status": "Approved",
        "halfYear": "1",
        "year": 2025
      }
      const apidata: any = await getMasterLeave(objectToQueryString(query));
      const APIResponse = apidata?.data?.data;
      let valueInBeginning = 0;
      let totalExpenditure = APIResponse.reduce((accumVariable: any, curValue: any) => accumVariable + Number(curValue.days), valueInBeginning)
      setLeaveData4(totalExpenditure);
      setIsLoadingLeaveData4(true)
    };
    getData4();

  }, [userList])

  return (
    <Wrapper pageTitle="Dashboard" breadcrumbList={breadcrumbList}>
      {leaveType === 'allUsers' ?
        <div className="pageGropupBtn">
          <Button onClick={() => setShowContent('table')} className={showContent === 'table' ? 'active' : 'inactive'} buttonStyleOutline buttonStyleType={showContent === 'table' ? 'link' : 'link'}>Leave Approval Management</Button>
          <Button onClick={() => setShowContent('calender')} className={showContent === 'calender' ? 'active' : 'inactive'} buttonStyleOutline buttonStyleType={showContent === 'calender' ? 'link' : 'link'}>Calender View</Button>
        </div>
        :
        <div className="pageGropupBtn">
          <Button onClick={() => setShowContent('calender')} className={showContent === 'calender' ? 'active' : 'inactive'} buttonStyleOutline buttonStyleType={showContent === 'calender' ? 'link' : 'link'}>Calender View</Button>
          <Button onClick={() => setShowContent('table')} className={showContent === 'table' ? 'active' : 'inactive'} buttonStyleOutline buttonStyleType={showContent === 'table' ? 'link' : 'link'}>Table View</Button>
        </div>
      }
      <Card>
        <div className="">
          {!isValid && (
            <Alert
              AlertStyleType="danger"
              AlertText={`Please enter required fields`}
            ></Alert>
          )}
          <div className="row">
            <div className="col-md-12">
              {showContent === 'calender' ?
                <>
                  <div className="row align-content-end justify-content-start">
                    <div className="col-md-6">
                      <h4 className="card-title">{pageData}</h4>
                      <p>{pageDescData}</p></div>
                    <div className="col-md-3">
                      <div className="row align-content-end justify-content-end" style={{ marginRight: "10px" }}>
                        <p className={`col-md-4 line Pending`}>Pending</p>
                        <p className={`col-md-4 line Approved`}>Approved</p>
                        <p className={`col-md-4 line Rejected`}>Rejected</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex justify-content-end">
                        {useFilter?.length > 0 &&
                          <Filters
                            sidebarFilterData={filterData}
                            filter={useFilter}></Filters>
                        }
                        <TableDownload
                          data={tableDataCalendar}
                          tableTitle={'Day wise leave'}
                          sortingData={tableDataCalendar}
                          disabled={tableDataCalendar.length === 0}
                          downloadKeys={LeaveCalenderDownloadKey}
                        />
                      </div>
                    </div>
                  </div>
                  <InlineCalendar
                    type="leave"
                    calendarData={tableDataCalendar}>
                  </InlineCalendar>
                </>
                :
                <CustomTable
                  header={tableConfig.tableHeader}
                  sortDefault={tableConfig.sortDefault}
                  data={tableData}
                  tableTitle={pageData}
                  tableSubTitle={pageDescData}
                  dropdown={""}
                  search_bar={true}
                  showCellCallback={showCellCallback}
                  sortState={sortState}
                  isLoading={isLoading}
                  downloadKeys={LeaveTableDownloadKey}
                  filter={useFilter}
                  sidebarFilterData={filterData}
                >
                  {({ rowData, index }: { rowData: any; index: any }) => (
                    <LeaveRow
                      rowData={rowData}
                      index={index}
                      showCells={showCells}
                      reloadData={reloadData}
                      callbackId={callbackFresh}
                    ></LeaveRow>
                  )}
                </CustomTable>
              }
            </div>
          </div>
        </div>

        {(actionType?.type === "add" || actionType?.type === "edit") && (
          <SideDrawer
            size={'850px'}
            pagetitle={`Details for ${actionType?.data?.leave_reason}`}
            action={CloseDrawer}
          >
            <div className="p-1">
              {actionType?.mainType === 'schedule' ?
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Leave Reason</th>
                      <th>Leave Type</th>
                      <th>Date</th>
                      <th>Leave Day Type</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableDataCalendarSingle?.map((item: any) => {
                      return (<tr>
                        <td> {item?.leave_reason} </td>
                        <td> {item?.leave_type} </td>
                        <td> {item?.date} </td>
                        <td> {item?.leaveDay === '0.5' ? <span className="tag">Half Day</span> : 'Full Day'} </td>
                        <td> {moment(item?.start_date).format('DD/MM/YYYY')}</td>
                        <td> {moment(item?.end_date).format('DD/MM/YYYY')}</td>
                        <td> {item?.user_name} </td>
                      </tr>)
                    })}
                  </tbody>
                </table>
                :
                <>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th>User</th>
                        <td>{tableDataManagerSingle?.[0]?.user_name} </td>
                      </tr>
                      <tr>
                        <th>Date</th>
                        <td>From {moment(tableDataManagerSingle?.[0]?.start_date).format('DD/MM/YYY')} To {moment(tableDataManagerSingle?.[0]?.end_date).format('DD/MM/YYY')}
                          <span className="red">  (Days {tableDataManagerSingle?.[0]?.days})</span></td>
                      </tr>

                      <tr>
                        <th>Leave Reason / Leave Type</th>
                        <td>{tableDataManagerSingle?.[0]?.leave_reason} / {tableDataManagerSingle?.[0]?.leave_type}</td>
                      </tr>

                      <tr>
                        <th>LOP / Leave Approval Required</th>
                        <td>{tableDataManagerSingle?.[0]?.isPaidLeave === 'true' ? 'Yes' : 'No'} / {tableDataManagerSingle?.[0]?.isLeaveAppovalReq === 'true' ? 'Yes' : 'No'}</td>
                      </tr>
                      <tr>
                        <th colSpan={2}>
                          <table style={{ width: "100%" }}>
                            <tr>
                            <td><LeaveBox isLoading={isLoadingleaveData4} userDetailsData={userDetailsData?.leaves4} title={'From Jan - June 2025'} leaveData={leaveData4}></LeaveBox></td>
                              <td><LeaveBox isLoading={isLoadingleaveData3} userDetailsData={userDetailsData?.leaves3} title={'From July - Dec 2024'} leaveData={leaveData3}></LeaveBox></td>
                              {/* <td><LeaveBox isLoading={isLoadingleaveData2} userDetailsData={userDetailsData?.leaves} title={'From Jan - June 2024'} leaveData={leaveData2}></LeaveBox></td> */}
                              {/* <td><LeaveBox isLoading={isLoadingleaveData} userDetailsData={userDetailsData?.leaves2} title={'From July - Dec 2023'} leaveData={leaveData}></LeaveBox></td> */}
                            </tr>
                          </table>
                        </th>
                      </tr>
                    </tbody>
                  </table>


                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Email ID</th>
                        <th>Details</th>
                        <th>Remark</th>
                      </tr>
                    </thead>
                    <tbody>

                      {tableDataManagerSingle?.map((item: any) => {

                        return item?.manager_email_id === profile_details?.email_id && (
                          <tr>
                            <td> {item?.manager_email_id} </td>
                            <td>

                              <p className="none"><label>Status</label> {item?.status}</p>

                            </td>
                            <td style={{ "width": "200px" }}>
                              {(leaveType === 'allUsers' && profile_details?.user_type === 'admin') || (leaveType === 'allUsers' && profile_details?.user_type === 'leave-approver')
                                || (leaveType === 'allUsers' && isLOPAssignee?.value?.length > 0)
                                ?
                                <>
                                  <Select
                                    inputName={item?._id}
                                    options={meetingRoomName}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    selectedItem={
                                      item?.status === 'Pending' ?
                                        meetingRoomName.find((itemStatus) => {
                                          return (itemStatus.value === formData?.status)
                                        }) :
                                        meetingRoomName.find((itemStatus) => {
                                          return (itemStatus.value === item?.status)
                                        })
                                    }
                                    required={true}
                                    placeholder="Select status"
                                    search_option={false}
                                    isLoading={true}
                                    disabled={profile_details?.user_type === 'admin' ? false : item?.manager_email_id !== profile_details?.email_id}
                                  ></Select>
                                </> : item?.remark}
                            </td>
                          </tr>)
                      })}


                      {tableDataManagerSingle?.map((item: any) => {
                        return item?.manager_email_id !== profile_details?.email_id && (
                          <tr>
                            <td> {item?.manager_email_id} </td>
                            <td>

                              <p className="none"><label>Status</label> {item?.status}</p>

                            </td>
                            <td style={{ "width": "200px" }}>
                              {(leaveType === 'allUsers' && profile_details?.user_type === 'admin') || (leaveType === 'allUsers' && profile_details?.user_type === 'leave-approver') ?
                                <>
                                  <Select
                                    inputName={item?._id}
                                    options={meetingRoomName}
                                    onChangeSingleCallback={onChangeSingleCallback}
                                    selectedItem={
                                      item?.status === 'Pending' ?
                                        meetingRoomName.find((itemStatus) => {
                                          return (itemStatus.value === formData?.status)
                                        }) :
                                        meetingRoomName.find((itemStatus) => {
                                          return (itemStatus.value === item?.status)
                                        })
                                    }
                                    required={true}
                                    placeholder="Select status"
                                    search_option={false}
                                    isLoading={true}
                                    disabled={profile_details?.user_type === 'admin' ? false : item?.manager_email_id !== profile_details?.email_id}
                                  ></Select>
                                </> : item?.remark}
                            </td>

                          </tr>)
                      })}

                    </tbody>
                  </table>
                </>
              }
            </div>
            <div className="clearfix"></div>
          </SideDrawer>
        )}
      </Card>
    </Wrapper>
  );
};
export default AddConferenceRoom;
